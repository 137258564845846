import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import { DataProvider } from './context/store/store.context';
import { AlphaProvider } from './context/alpha/alpha.context';
import { Context } from './context/wallet/wallet.context';
import { ModalProvider } from './context/modal/modal.context';
import { StakingProvider } from './context/staking/staking.context';
import App from './App';
import { Web3Provider } from './context/web3/web3.context';

render(
  <StrictMode>
    <BrowserRouter>
      <DataProvider>
        <Context>
          <ModalProvider>
            <Web3Provider>
              <StakingProvider>
                <AlphaProvider>
                  <GlobalStyles />
                  <App />
                </AlphaProvider>
              </StakingProvider>
            </Web3Provider>
          </ModalProvider>
        </Context>
      </DataProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
