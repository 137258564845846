import React, { createContext, useReducer } from 'react';
import alphaReducer from './alpha.reducer';
import { IAlphaState } from './alpha.types';
import { marketEntryTemplate } from '../../pages/alpha/admin/forms/constants';

const INITIAL_STATE: IAlphaState = {
  loading: false,
  user: {
    _id: '',
    username: '',
    pfp: '',
    member: false,
    isAdmin: false,
    pubKey: [],
    nfts: {
      wallet: [],
      staked: [],
    },
  },
  calendar: [],
  blog: [],
  market: {
    products: [],
    selectedProduct: marketEntryTemplate,
    purchases: [],
  },
};

export const AlphaContext = createContext<{
  state: IAlphaState;
  dispatch: React.Dispatch<any>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const AlphaProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(alphaReducer, INITIAL_STATE);

  return <AlphaContext.Provider value={{ state, dispatch }}>{children}</AlphaContext.Provider>;
};
