import React, { createContext } from 'react';

import { Metadata } from '@metaplex-foundation/mpl-token-metadata';
import { Connection, PublicKey, LAMPORTS_PER_SOL, clusterApiUrl } from '@solana/web3.js';
import { AccountLayout, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useWallet } from '@solana/wallet-adapter-react';
import { Program, Provider } from '@project-serum/anchor';

import axios from 'axios';

import idl from '../../idl/updatedStakingFive.json';
import hashes from '../staking/hashes.json';
import ooo from '../staking/ooo.json';

import marketIdl from '../../idl/marketIdl.json';

const preflightCommitment = 'processed';
const commitment = 'confirmed';
// const connectionConfig = { commitment: 'confirmed', confirmTransactionInitialTimeout: 60000 };
const programID = new PublicKey(idl.metadata.address);

const marketProgramID = new PublicKey(marketIdl.metadata.address);
const devnet = clusterApiUrl('devnet');

// .env
const syndica =
  'https://solana-api.syndica.io/access-token/gDcBtRpIMF8CTsmbM2wlxntQpKP2J77WBUE6gsnBm946I3E6O9MIziwYlwctOMbj/rpc';

export const Web3Context = createContext<any>({});

export const Web3Provider: React.FC = ({ children }) => {
  const wallet = useWallet();

  const connection = new Connection(syndica, {
    commitment: 'confirmed',
    confirmTransactionInitialTimeout: 120000,
  });

  // const devnetConnection = new Connection(devnet, {
  //   commitment: 'confirmed',
  //   confirmTransactionInitialTimeout: 120000,
  // });
  // @ts-ignore
  // const devnetProvider = new Provider(devnetConnection, wallet, { preflightCommitment, commitment });
  // @ts-ignore

  // @ts-ignore
  const provider = new Provider(connection, wallet, { preflightCommitment, commitment });
  // @ts-ignore
  const program = new Program(idl, programID, provider);
  //@ts-expect-error
  const marketProgram = new Program(marketIdl, marketProgramID, provider);

  // get balance of sol in current wallet
  const getSolBalance = async (publicKey: PublicKey) => {
    const response = await connection.getAccountInfo(publicKey);
    // @ts-ignore
    return response.lamports / LAMPORTS_PER_SOL;
  };

  // get balance of magnum token in current wallet
  const getMagBalance = async (publicKey: PublicKey, type: string) => {
    const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
      programId: TOKEN_PROGRAM_ID,
    });

    const balances = tokenAccounts.value.map(e => {
      const accountInfo = AccountLayout.decode(e.account.data);
      return { pubKey: accountInfo.mint.toString(), balance: accountInfo.amount };
    });

    if (type === 'coins') {
      return balances.find(b => b.pubKey === 'MAGf4MnUUkkAUUdiYbNFcDnE4EBGHJYLk9foJ2ae7BV');
    } else {
      return balances.filter(b => [...hashes, ...ooo].includes(b.pubKey));
    }
  };

  // get metadata by mint public key
  const getTokenMetaData = async mint => {
    const tokenMetaPK = await Metadata.getPDA(mint);
    const tokenMetaData = await Metadata.load(connection, tokenMetaPK);
    const val = await axios.get(tokenMetaData.data.data.uri);

    return val;
  };

  // get magnum nfts in given wallet
  const getNFTS = async (publicKey: PublicKey) => {
    const tokens = await getMagBalance(publicKey, 'nfts');

    const nfts = await Promise.all(
      (tokens as any[])
        .filter(token => token.balance.toString() > 0)
        ?.map(async token => ({
          mint: token.pubKey,
          metadata: await getTokenMetaData(token.pubKey),
        }))
    );
    return nfts;
  };

  // get currently staked magnum nfts
  const getStakedNFTS = async () => {
    const {
      provider: { wallet },
    } = program;
    const allStaked = await program.account.stakeAccount.all();
    if (allStaked.length < 1) return;

    const userStaked = allStaked.filter(
      token => token.account.stakingTokenOwner.toString() === wallet?.publicKey.toString()
    );

    if (userStaked.length < 1) return;
    return await Promise.all(
      userStaked.map(async token => {
        return {
          mint: token.account.stakingMint,
          metadata: await getTokenMetaData(token.account.stakingMint),
        };
      })
    );
  };

  const sendMarketPayment = () => {
    console.log('market payment');
  };

  return (
    <Web3Context.Provider
      value={{
        wallet,
        connection,
        getSolBalance,
        getMagBalance,
        getNFTS,
        getStakedNFTS,
        getTokenMetaData,
        marketProgram,
        sendMarketPayment,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
