import React, { useEffect } from 'react';
import Router from './router';
import useConsoleStart from './hooks/useConsoleStart';
import useStore from './context/store/store.actions';
import { useWallet } from '@solana/wallet-adapter-react';

const App = () => {
  const { getAttributes, getTeam, getContent, setLoading } = useStore();
  // const wallet = useWallet();

  // useEffect(() => console.log(wallet, wallet.publicKey?.toString()), [wallet]);
  // const token = () => Cookies.get('token_magmin');
  // const init = useConsoleStart();

  // useEffect(() => init(), []);

  // const checkAdmin = async () => {
  //   const pubKey = wallet?.publicKey.toString();

  //   const login = await axios.post(
  //     `${process.env.REACT_APP_API_ORIGIN}/users/admins/auth/${pubKey}/${process.env.REACT_APP_API_KEY}`,
  //     {
  //       withCredentials: true,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //   );

  //   const token = login.data.token;
  //   document.cookie = `token_magmin=${token}`;
  //   // console.log(token());
  // };

  // useEffect(() => {
  //   const getTxs = async () => {
  //     if (wallet) {
  //       getAllTransactions();
  //     }
  //   };

  //   getTxs();
  // }, [wallet]);

  useEffect(() => {
    const seed = async () => {
      setLoading(true);
      await getAttributes();
      await getContent();
      setLoading(false);
    };
    seed();
  }, []);

  return (
    <>
      <Router />
    </>
  );
};

export default App;
