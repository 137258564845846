export const tags = [
  'Tools',
  'Revenue Share',
  'Passive income',
  'PFP',
  'Metaverse',
  'Staking',
  'Defi',
  'P2E',
  'Video Game',
  'Gambling',
  'Marketplace',
  'Launchpad',
  'Art',
  '1/1',
  'Music',
  'Sports',
  'Photography',
  'Data',
  'Merchandise',
  'Comic Book',
  'Animated Series',
  'Utility',
  'DAO',
  'Gamefied',
  'Doxxed',
  'Rug',
  'B2B',
];

const mintTemplate = {
  date: 'TBA',
  display_time: false,
  price: 0,
  supply: 0,
};

const interactionsTemplate = {
  upvotes: [],
  downvotes: [],
};

export const calEntryTemplate = {
  name: '',
  image: '',
  mint: mintTemplate,
  socials: {
    discord: {
      link: '',
    },
    twitter: {
      link: '',
      followers: 0,
      bio: '',
    },
    website: {
      link: '',
    },
  },
  mag_grade: 0,
  tags: [],
  interactions: interactionsTemplate,
};

export const marketEntryTemplate = {
  _id: '',
  name: '',
  image: '',
  description: '',
  price: 0,
  inventory: 0,
  per_wallet: 0,
  deadline: '',
  active: true,
};

export const blogEntryTemplate = {
  title: '',
  image: '',
  author: {},
  project: {
    mint: mintTemplate,
    socials: {
      twitter: '',
      discord: '',
      website: '',
      medium: '',
      instagram: '',
      telegram: '',
    },
  },
  body: {
    html: '',
    draft: null,
  },
  tags: [],
  interactions: interactionsTemplate,
  createdAt: '',
};
