import { IDataState } from './store.types';

export default function dataReducer(state: IDataState, { type, payload }) {
  switch (type) {
    case 'LOADING':
      return { ...state, loading: payload };
    case 'GET_ATTRIBUTES':
      return { ...state, nft: { ...state.nft, attributes: payload[0] } };
    case 'GET_LEGENDARIES':
      return { ...state, nft: { ...state.nft, legendaries: payload } };
    case 'GET_TEAM':
      return { ...state, team: payload };
    case 'GET_CONTENT':
      return { ...state, content: payload };
    case 'GET_TRANSACTIONS':
      return { ...state, stakingTransactions: payload };
    case 'CREATE_TRANSACTION':
      return { ...state, stakingTransactions: [...state.stakingTransactions, payload] };
    default:
      break;
  }
  return state;
}
