import React, { createContext, useReducer } from 'react';
import stakingReducer from './staking.reducer';
import { IStakingState } from './staking.types';

const INITIAL_STATE: IStakingState = { staked: [], unstaked: [], loading: false };

export const StakingContext = createContext<{
  state: IStakingState;
  dispatch: React.Dispatch<any>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const StakingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(stakingReducer, INITIAL_STATE);

  return <StakingContext.Provider value={{ state, dispatch }}>{children}</StakingContext.Provider>;
};
