import React, { createContext, useState } from 'react';

interface IModalContext {
  isOpen: boolean;
  toggleModal?: (boolean?) => void;
}

const defaultState = {
  isOpen: false,
};

export const ModalContext = createContext<IModalContext>(defaultState);

export const ModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(defaultState.isOpen);

  const toggleModal = (val?: boolean) => {
    setIsOpen(val || !isOpen);
  };

  return <ModalContext.Provider value={{ isOpen, toggleModal }}>{children}</ModalContext.Provider>;
};
