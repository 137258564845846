/* eslint-disable no-case-declarations */
import { IAlphaState, ICalendar, IBlog } from './alpha.types';

export default function alphaReducer(state: IAlphaState, { type, payload }) {
  let arr;
  let obj;
  switch (type) {
    case 'UPDATE_ALPHA_USER':
      return { ...state, user: payload };
    case 'UPDATE_CONNECTED_WALLET':
      return {
        ...state,
        user: {
          ...state.user,
          pubKey:
            state.user.pubKey !== null
              ? (state.user.pubKey as string[]).includes(payload)
                ? state.user.pubKey
                : [...(state.user.pubKey as string[]), payload]
              : [payload],
        },
      };
    case 'GET_ALL_CALENDAR':
      return { ...state, calendar: payload };
    case 'UPDATE_CALENDAR_ENTRY':
      arr = state.calendar;
      obj = arr.indexOf(arr.find(entry => entry._id === payload._id) as ICalendar);
      arr[obj] = payload;
      return { ...state, calendar: arr };
    case 'DELETE_CALENDAR_ENTRY':
      return {
        ...state,
        calendar: state.calendar.filter(entry => entry._id !== payload),
      };
    case 'UPDATE_BLOG_ENTRY':
      arr = state.blog;
      obj = arr.indexOf(arr.find(entry => entry._id === payload._id) as IBlog);
      arr[obj] = payload;
      return { ...state, calendar: arr };
    case 'DELETE_BLOG_ENTRY':
      return {
        ...state,
        blog: state.blog.filter(entry => entry._id !== payload),
      };
    case 'GET_ALL_BLOG':
      return { ...state, blog: payload };
    case 'GET_ALL_MARKETPLACE_PRODUCTS':
      console.log('reducer', payload);
      return {
        ...state,
        market: {
          ...state.market,
          products: payload,
        },
      };
    case 'SELECT_PRODUCT':
      return {
        ...state,
        market: {
          ...state.market,
          selectedProduct: payload,
        },
      };
    case 'UPDATE_USER_PURCHASES':
      return {
        ...state,
        market: {
          ...state.market,
          purchases: payload,
        },
      };
    case 'UPDATE_USER_NFTS':
      return {
        ...state,
        user: {
          ...state.user,
          nfts: payload,
        },
      };
    default:
      break;
  }
  return state;
}
