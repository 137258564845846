import React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';
import bg from './bg.json';

const CustomStyles = createGlobalStyle`
:root {
  --white-primary: #faf8fe;

  --black-primary: #000000;
  --black-secondary: #100f12;

  --grey-primary: ##1b1b1bb0;
  --grey-secondary: rgba(0,0,0,0.3);

  --orange-primary: #ff6e15;

  --red-primary: #d33d4f;
  --red-secondary: #d33544;

  --pink-primary: #ff00b9;
  --pink-secondary: #b33ead;

  --yellow-primary: #e4a115;
  --yellow-secondary: #e9b422;

  --purple-primary: #6e20e2;
  --purple-secondary: #bd3ae2;

  --cyan-primary: #00ffff;
  --cyan-secondary: #219895;

  --discord: #7289da;
  --twitter: #1da1f2;
  --linkedin: #0e76a8;
  --instagram: #de1885;
  --twitch: #6441a5;

  --font-game: 'hydrophilia-iced';
  --font-oswald: 'Oswald';
  --font-primary: 'lores-12';
  --font-neon: 'Raleway';
}

body {
  line-height: 175%;
  ${tw`overflow-x-hidden text-white text-base transition-all duration-200 bg-black-primary bg-repeat bg-center font-primary font-normal uppercase text-lg`}
  background-size: 480px;
  background-image: url(${bg});
}

h1 {
  ${tw`text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-play font-bold lg:leading-[75px]`}
}

h2 {
  line-height: 150%;
  ${tw`text-lg md:text-xl lg:text-2xl text-yellow-secondary`}
}

section {
  ${tw`mb-10 md:mb-16 min-h-[33vh] 2xl:min-h-[20vh]`}
}

.carousel-item {
  margin: 0 3rem;
}

.wallet-adapter-button-trigger {
  ${tw`font-primary uppercase rounded-lg p-3 text-center transition-all duration-700 ease-in-out hover:shadow-lg text-white bg-transparent`}
  background-image: linear-gradient(
    to right,
    var(--purple-primary) 20%,
    var(--purple-primary) 21%,
    #DC1FFF 41%,
    var(--cyan-primary) 81%,
    #00FFA3 100%);
    background-size: 350% auto;
    font-weight: 900 !important;
    border: 1px solid var(--purple-primary);

    &:hover {
      ${tw`border-black-primary text-black-primary shadow-xl`}
      background-position: right;
    }
}

input {
  color-scheme: dark;
}

ol,
ul {
  list-style-type: revert;
  margin: revert;
  padding: revert;
}
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
