import React from 'react';

const Spinner = () => {
  return (
    <div tw='fixed top-0 left-0 bg-grey-secondary flex justify-center items-center w-screen h-screen-full'>
      <div tw='w-32 flex justify-center items-center'>
        <img src={require('url:../../assets/spinner.gif')} alt='spinner' />
      </div>
    </div>
  );
};

export default Spinner;
