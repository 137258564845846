import { IStakingState } from './staking.types';

export default function stakingReducer(state: IStakingState, { type, payload }) {
  switch (type) {
    case 'UPDATE_UNSTAKED':
      return {
        ...state,
        unstaked: payload,
      };
    case 'UPDATE_STAKED':
      return {
        ...state,
        staked: payload,
      };
    case 'LOADING':
      return {
        ...state,
        loading: payload,
      };
    default:
      break;
  }
  return state;
}
