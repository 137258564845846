import { useContext } from 'react';
import { DataContext } from './store.context';
import axios from 'axios';

export default function useStore() {
  const { state, dispatch } = useContext(DataContext);

  const origin = process.env.REACT_APP_API_ORIGIN;
  const key = process.env.REACT_APP_API_KEY;

  const getAttributes = async () => {
    try {
      // dispatch({ type: 'LOADING', payload: true });

      const res = await axios.get(`${origin}/v1/nft/attributes/${key}`);

      if (res) dispatch({ type: 'GET_ATTRIBUTES', payload: res.data });
    } catch (err) {
      console.error(err);
    }
    //  finally {
    //   dispatch({ type: 'LOADING', payload: false });
    // }
  };

  const getLegendaries = async () => {
    try {
      // dispatch({ type: 'LOADING', payload: true });
      const res = await axios.get(`${origin}/v1/nft/one_of_one/${key}`);

      if (res) dispatch({ type: 'GET_LEGENDARIES', payload: res.data });
    } catch (err) {
      console.error(err);
    }
    // finally {
    //   dispatch({ type: 'LOADING', payload: false });
    // }
  };

  const getTeam = async () => {
    try {
      // dispatch({ type: 'LOADING', payload: true });
      const res = await axios.get(`${origin}/v1/team/${key}`);

      if (res) dispatch({ type: 'GET_TEAM', payload: res.data });
    } catch (err) {
      console.error(err);
    }
    //  finally {
    //   dispatch({ type: 'LOADING', payload: false });
    // }
  };

  const getContent = async () => {
    try {
      // dispatch({ type: 'LOADING', payload: true });
      const res = await axios.get(`${origin}/v1/web/${key}`);

      if (res) dispatch({ type: 'GET_CONTENT', payload: res.data[0] });
    } catch (err) {
      console.error(err);
    }
    // finally {
    //   dispatch({ type: 'LOADING', payload: false });
    // }
  };

  const createTransaction = async transaction => {
    try {
      // dispatch({ type: 'LOADING', payload: true });
      const res = await axios.post(`${origin}/v1/staking/${key}`, transaction);

      // console.log(res.data);
      if (res) dispatch({ type: 'CREATE_TRANSACTION', payload: res.data });
    } catch (err) {
      console.error(err);
    }
    // finally {
    //   dispatch({ type: 'LOADING', payload: false });
    // }
  };

  const getAllTransactions = async () => {
    try {
      // dispatch({ type: 'LOADING', payload: true });
      const res = await axios.get(`${origin}/v1/staking/${key}`);

      if (res) dispatch({ type: 'GET_TRANSACTIONS', payload: res.data });
    } catch (err) {
      console.error(err);
    }
    // finally {
    //   dispatch({ type: 'LOADING', payload: false });
    // }
  };

  const setLoading = bool => {
    dispatch({ type: 'LOADING', payload: bool });
  };

  return {
    state,
    getAttributes,
    getLegendaries,
    getTeam,
    getContent,
    createTransaction,
    getAllTransactions,
    setLoading,
  };
}
