import React, { createContext, useReducer } from 'react';
import dataReducer from './store.reducer';
import { IDataState } from './store.types';

const INITIAL_STATE: IDataState = {
  loading: false,
  nft: {
    attributes: {
      backgrounds: [],
      faces: [],
      bodies: [],
      face_gear: [],
      glasses: [],
      costumes: [],
      head_gear: [],
      mannequin: [],
    },
    legendaries: [],
  },
  team: [],
  content: {
    logo: {
      small: '',
      large: '',
    },
    lore: {
      banner: '',
      figure: '',
    },
    roadmap: {
      banner: {
        evolution: '',
        stasis: '',
        token: '',
      },
    },
    arcade: '',
    footer: {
      banner: '',
    },
    bobble: '',
    trailer: {
      video: '',
      poster: '',
    },
  },
  stakingTransactions: [],
};

export const DataContext = createContext<{
  state: IDataState;
  dispatch: React.Dispatch<any>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const DataProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, INITIAL_STATE);

  return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>;
};
