import React, { lazy, Suspense } from 'react';
import Spinner from '../components/spinner';
import { Routes, Route } from 'react-router-dom';

const MainRoute = lazy(() => import('./MainRoute'));
const AlphaRoute = lazy(() => import('./AlphaRoute'));
const ModalRoute = lazy(() => import('./ModalRoute'));
const AdminRoute = lazy(() => import('./AdminRoute'));
const Contact = lazy(() => import('../pages/contact'));
const Main = lazy(() => import('../pages/main'));
const Rarities = lazy(() => import('../pages/rarities'));
// const Staking = lazy(() => import('../pages/staking'));
const Marketplace = lazy(() => import('../pages/alpha/marketplace'));
const Blog = lazy(() => import('../pages/alpha/blog'));
const MarketplaceForm = lazy(() => import('../pages/alpha/admin/forms/Marketplace'));
const NFT = lazy(() => import('../pages/NFT'));
const Dashboard = lazy(() => import('../pages/alpha/dashboard'));
/* const Calendar = lazy(() => import('../pages/alpha/calendar'));
const CalendarCard = lazy(() => import('../pages/alpha/calendar/CalendarCard')); */
const EntryForm = lazy(() => import('../pages/alpha/admin/forms/post'));
const EntryFormPut = lazy(() => import('../pages/alpha/admin/forms/put'));
const NotFound = lazy(() => import('../pages/404'));

const LazyRoute = (props: { el: JSX.Element }) => <Suspense fallback={<Spinner />}>{props.el}</Suspense>;
const TokenImg = () => <img src={require('url:../assets/mag_coin.png')} alt='token' />;

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<LazyRoute el={<MainRoute />} />}>
        <Route index element={<LazyRoute el={<Main />} />} />
        <Route path='contact' element={<LazyRoute el={<Contact />} />} />
        <Route path='rarity' element={<LazyRoute el={<Rarities />} />} />
        {/* <Route path='staking' element={<LazyRoute el={<Staking />} />} /> */}
      </Route>
      <Route path='/token' element={<TokenImg />} />
      <Route path='/*' element={<LazyRoute el={<NotFound />} />} />
      <Route path='alpha/' element={<LazyRoute el={<AlphaRoute />} />}>
        {/* dashboard */}
        <Route index element={<LazyRoute el={<Dashboard />} />} />
        <Route path='marketplace/' element={<LazyRoute el={<Marketplace />} />}></Route>
        <Route path='collection/:hash' element={<LazyRoute el={<ModalRoute />} />}>
          <Route index element={<LazyRoute el={<NFT />} />} />
        </Route>
        {/* <Route path='calendar/' element={<LazyRoute el={<Calendar />} />} />
        <Route path='calendar/:_id/' element={<LazyRoute el={<ModalRoute />} />}>
          <Route index element={<LazyRoute el={<CalendarCard />} />} />
          <Route path='edit' element={<LazyRoute el={<EntryFormPut />} />} />
        </Route> */}
        <Route path='reviews' element={<LazyRoute el={<Blog />} />} />
        <Route path='reviews/:_id' element={<LazyRoute el={<ModalRoute />} />}>
          <Route path='edit' element={<LazyRoute el={<EntryFormPut />} />} />
        </Route>
        <Route path='post/' element={<LazyRoute el={<AdminRoute />} />}>
          {/* <Route path='calendar' element={<LazyRoute el={<ModalRoute />} />}>
            <Route index element={<LazyRoute el={<EntryForm />} />} />
          </Route> */}
          <Route path='review' element={<LazyRoute el={<ModalRoute />} />}>
            <Route index element={<LazyRoute el={<EntryForm />} />} />
          </Route>
          <Route path='marketplace/' element={<LazyRoute el={<ModalRoute />} />}>
            <Route index element={<LazyRoute el={<MarketplaceForm />} />} />
            {/* <Route index element={<LazyRoute el={<MarketForm />} />} /> */}
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
