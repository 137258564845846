{
  "version": "0.1.0",
  "name": "utility",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [],
      "args": []
    },
    {
      "name": "purchaseMarketplaceItem",
      "accounts": [
        {
          "name": "sender",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "senderMagaiTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magaiBankTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    }
  ],
  "metadata": {
    "address": "HVYZksgJEbNp1s9Bu9sfjrd61WpKcVFkfzhE85h53qTf"
  }
}
